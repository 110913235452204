import {
  Avatar,
  Button,
  CardActions,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material'
import { useAuthentication, useEpiContent } from '@trr/app-shell-data'

import { Content } from 'types'
import FavoriteIcon from '@mui/icons-material/Favorite'

const NoSaves = () => {
  const content = useEpiContent<Content>()
  const { userRoles } = useAuthentication()
  const showForAllButTioAvslutad = !userRoles.includes('TIOAvslutad')
  return (
    <>
      <CardContent data-testid="noSavedJobs">
        <Typography variant="h6" component={'h2'} mb={1}>
          {content.heading}
        </Typography>
        <List>
          <ListItem disablePadding disableGutters divider={false}>
            <ListItemAvatar sx={{ display: { xs: 'none', sm: 'initial' } }}>
              <Avatar sx={{ bgcolor: 'secondary.light' }}>
                <FavoriteIcon color="secondary" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{ fontSize: 14 }}
              primary={content.noSavesDescription}
            />
          </ListItem>
        </List>
      </CardContent>
      {showForAllButTioAvslutad && (
        <CardActions>
          <Button
            data-testid="noSavedJobsLink"
            href={content.noSavesLinkUrl}
            size="small"
            variant="text"
          >
            {content.noSavesLinkText}
          </Button>
        </CardActions>
      )}
    </>
  )
}

export default NoSaves
